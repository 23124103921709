import React, { useRef } from 'react';
import { h, render } from 'preact';
import { useCallback, useEffect, useState, useMemo } from 'preact/hooks';
import PropTypes from 'prop-types';
import SVGIcon from 'common/components/svg-icon.jsx';

const getModelIcon = (model) => {
    const modelIcon = model?.icon;
    const theme = model?.properties?.additionalInfo;
    let modelLabel = '';

    if (!modelIcon || !modelIcon.html) {
        return '';
    }

    switch (theme) {
        case 'theme-pl-live':
            modelLabel = `${modelIcon.html}-white`;
            break;
        case 'theme-pl':
            modelLabel = `${modelIcon.html}-white`;
            break;
        default:
            modelLabel = modelIcon.html;
    }

    return modelLabel;
};

function checkString(str) {
    // Regular expression to match a string that starts with 't' (case-insensitive) and has a number after
    const regex = /^t.*\d/i;
    
    // Test the string against the regular expression
    return regex.test(str);
  }

/**
 *
 * @param root0
 * @param root0.player
 * @param root0.keyEvent
 * @param root0.liveBlogData
 */
export default function BlogEntry( { blogEntryData, isPinned=false } ) {
    const blogEntry = useRef( null );
    let theme = '';
    let optaId = ''
    let clubBadge = ''
    let clubBadgeRetina = ''
    let showClubTheme = ''
    let gameTime = ''

    useEffect( () => {
        if ( blogEntry.current ) {
            PULSE.app.common.refreshScripts(blogEntry.current);

            new PULSE.app.LazyLoad(blogEntry.current);
    
            window.dispatchEvent(
                new CustomEvent(PULSE.app.common.constants.EVENTS.SCRIPTS.LIVE_BLOG.LOADED)
            );
        }
    }, [])

    const blogIcon = blogEntryData ? getModelIcon( blogEntryData ) : null;

    if ( blogEntryData ) {
        // If an opta ID is given, update the entry model
        if (blogEntryData?.subtitle && checkString( blogEntryData.subtitle )) {
            optaId = blogEntryData.subtitle;
            clubBadge = PULSE.app.common.getClubBadge(
                blogEntryData.subtitle,
                '50'
            );
            clubBadgeRetina = PULSE.app.common.getClubBadge(
                blogEntryData.subtitle,
                '50'
            );
            // Decide whether to show club themed card or not
            showClubTheme =
                blogEntryData?.properties?.additionalInfo === 'theme-club';
        }

        // Update model to render card theme and gameTime
        theme = !blogEntryData?.properties?.additionalInfo?.includes( 'https://') ? blogEntryData?.properties?.additionalInfo : '';
        gameTime = blogEntryData?.properties?.score
            ? `${blogEntryData.properties?.score}'`
            : null;
    }

    const hasClubBadge = clubBadge !== '';

    return (
        blogEntryData ? (
            <div className={ `live-blog__blog-post ${ isPinned ? 'live-blog__blog-post--pinned' : '' } ${ theme } ${ showClubTheme ? optaId : '' } ${ hasClubBadge ? 'has-club-badge' : '' }` } ref={ blogEntry }>
                <div className={ `live-blog__blog-post-meta ${ !blogIcon && !hasClubBadge ? 'no-icon' : '' } ${ gameTime ? 'has-game-time' : ''}` }>
                    {
                        hasClubBadge ? (
                            <span className="badge badge-image-container" data-widget="club-badge-image" data-size="50">
                                <p>{ hasClubBadge }</p>
                                <img className="badge-image badge-image--20 js-badge-image" src={ clubBadge } srcSet={ `${ clubBadge }, ${ clubBadgeRetina } 2x` } />
                            </span>
                        ) : null
                    }
                    {
                        blogIcon && !hasClubBadge ? (
                            <img className="live-blog__blog-post-icon" src={ `https://resources.premierleague.com/premierleague/blog-icons/${ blogIcon }.png` } />
                        ) : null
                    }
                    <span className="live-blog__blog-post-time">
                        { moment(new Date(blogEntryData.timestamp)).locale( PULSE?.app?.language ? PULSE?.app?.language : 'EN' ).format('HH:mm') }
                        {
                            gameTime ? (
                                <span className="live-blog__blog-post-game-time">{ gameTime }</span>
                            ) : null
                        }
                    </span>

                    
                </div>

                <div className="live-blog__blog-post-content">
                    <h6 className="live-blog__blog-post-title">{ blogEntryData.title }</h6>
                    <span dangerouslySetInnerHTML={ { __html: blogEntryData.comment } }></span>
                    {
                        isPinned ? (
                            <div className="live-blog__blog-post-pinned">
                                <SVGIcon name="pin-white" cssClass="live-blog__blog-post-pinned-icon" />
                                <span className="live-blog__blog-post-pinned-text">Pinned</span>
                            </div>
                        ) : null
                    }
                </div>
            </div>
        ) : null
    );
}

BlogEntry.propTypes = {
    blogEntryData: PropTypes.object.isRequired,
    isPinned: PropTypes.boolean
};